import { clamp } from 'ramda';

/**
 * Truncate long string.
 *
 * @param {string} str
 * @param {number} max - maximum not-truncated length
 * @return {string}
 */
export const ellipsis = (str, max) => {
  if (str.length <= max) {
    return str;
  }

  const i = clamp(0, max, max - 3);

  return `${str.slice(0, i)}...`;
};
