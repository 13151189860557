import { mapGetters } from 'vuex';
import { ellipsis } from 'universkin-shared/src/utils/textFormat';

import { getCountryDisplayName } from '@/utils';

import { types } from '@/store/types';

export const displayNameMixin = {
  computed: {
    ...mapGetters({
      displayNameMixin__locale: types.getters.GET_LOCALE,
      displayNameMixin__userCountry: types.getters.GET_USER_COUNTRY
    })
  },
  methods: {
    getCountryDisplayName(countryCode, options) {
      if (!countryCode) {
        return '';
      }

      const { maxLength } = options || {};

      let locale = 'en';

      if (this.displayNameMixin__locale) {
        const { baseName: localeWithRegion } = new Intl.Locale(this.displayNameMixin__locale, {
          region: this.displayNameMixin__userCountry || undefined
        });
        locale = localeWithRegion;
      }

      const countryName = getCountryDisplayName(countryCode.toUpperCase(), locale);

      return maxLength ? ellipsis(countryName, maxLength) : countryName;
    }
  }
};
